const localeDefault = {
  // Login page
  "79_391": "Remember me",
  "79_377": "Email",
  "79_373": "Enter email",
  "79_385": "Password",
  "79_380": "Enter password",
  "79_393": "Forgot password?",
  "79_399": "2024",
  "79_400": "Organic Mobility Inc. All Rights Reserved",
  "111_17": "Privacy Policy",
  "111_18": "Terms of Service",
  // Reset Password page
  "I1_11;1": "Allow Special Symbol",
  "I1_11;2": "Allow Special Symbol",
  "79_472": "Reset password",
  "79_473": "Please kindly set your new password.",
  "79_482": "New password",
  "79_490": "Re-enter password",
  "79_519": "Change",
  "79_497": "Require alphabetic letter",
  "79_498": "Require lowercase letter",
  "79_499": "Require numbers",
  "79_496": "Allow Special Symbol",
  "79_503": "20 characters",
  "79_507": "7 characters",
  "79_501": "Mazimum Password Length (Characters)",
  "79_505": "Mazimum Password Length (Characters)",
  // Send Email page
  "I1_11;7": "Email is invalid!",
  "79_439": "First Login?",
  "I1_11;9": "Locked User",
  "109_113": "Forgot your password?",
  "I1_11;11": "Your account was locked out because you logged in incorrectly more than 5 times. ",
  "109_114":
    "Enter your email address below, and we’ll send you a link to reset your password.Follow the instructions in the email to create a new password.",
  "109_127": "Email",
  "I1_11;14": "Back to Login",
  "109_123": "me.g. username@kinety.com",
  "79_442": "Send Verification Code ",
  "109_116": "Send Reset Link",
  "79_444": "Back to Sign in ",
  // OTP Code page
  "79_456": "Enter Verification Code",
  "79_457":
    "We’ve sent a verification code to your email address. Please enter it below to continue verifying your account.",
  "79_468": "Your Verification Code",
  "79_463": "abczys",
  "79_458": "Resend Verification Code?",
  "79_460": "Next",
  // PasswordSuccess page
  "109_21": "Password Changed Successfully",
  "109_20":
    "Your password has been updated. You can now use your new password to sign in to your account.",
  "109_19": "Go to Sign In"
}
export default localeDefault
