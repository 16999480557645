/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser } from "api"
import { useEffect, useState } from "react"
import { setCookieAuth } from "lib/cookie"
import { isEmailValid } from "lib/validate"
import { ERROR_API_CODE } from "lib/const"
import CustomButton from "./CustomButton"
import "./index.css"
import { iconHide, iconShow, iconEmail, iconPassword } from "lib/image"
import { useTranslation } from "react-i18next"
import { ORG_TYPE, PORTAL_TYPE } from "lib/const"
import { jwtDecode } from "jwt-decode"
import { apiAms } from "api"

const Login = ({ onNext, onForgot }) => {
  const { t } = useTranslation()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  let tabId = sessionStorage.tabID

  const onChange = (v) => {
    setError("")
    setUsername(v)
  }

  async function handleSetDataTranslateErrorMsg() {
    try {
      const { data } = await apiAms.getTranslateErrorMessage()
      const errorMsg =
        data &&
        data.length &&
        data.map((item) => ({
          error_code: item.error_code,
          content: item.content,
          language_code: item.language_code
        }))
      errorMsg && localStorage.setItem("errorMsg", JSON.stringify(errorMsg))
    } catch (error) {}
  }

  const getPortalUrl = (orgType, userType) => {
    if (userType) {
      if (userType === PORTAL_TYPE.DRIVER) {
        return process.env.REACT_APP_CLIENT_DRIVER_PORTAL_URL
      }
      if (userType === PORTAL_TYPE.PASSENGER) {
        return process.env.REACT_APP_CLIENT_PASSENGER_PORTAL_URL
      }
    }
    if ([ORG_TYPE.PROVIDER_SERVICE_2B, ORG_TYPE.PROVIDER_SERVICE_2C].includes(orgType)) {
      return process.env.REACT_APP_CLIENT_ADMIN_PORTAL_URL
    }
    if (orgType === ORG_TYPE.USER_SERVICE) {
      return process.env.REACT_APP_CLIENT_CLIENT_PORTAL_URL
    }
  }

  const onFinish = async (e) => {
    e.preventDefault()
    if (!isEmailValid(username)) {
      setError("Email is invalid!")
      return
    }

    // const urlParams = new URLSearchParams(window.location.search)
    let res = {}
    try {
      setLoading(true)
      res = await apiUser.login({
        username: username.toLocaleLowerCase(),
        password
      })

      const { access_token, refresh_token } = res?.data
      let auth = {
        access_token,
        refresh_token
      }
      setCookieAuth(auth)
      const jwtData = jwtDecode(access_token)
      const userType = jwtData?.user_type
      const orgType = jwtData?.organization_type

      window.location.href = getPortalUrl(orgType, userType)
    } catch (error) {
      const { error_code, error_message, data } = error
      if (error_code === ERROR_API_CODE.User_Verify) {
        onNext(false, ERROR_API_CODE.User_Verify)
      } else if (error_code === ERROR_API_CODE.EndUser_Verify) {
        onNext(false, ERROR_API_CODE.EndUser_Verify)
      } else if (error_code === ERROR_API_CODE.User_Password_Expired) {
        onNext(true, ERROR_API_CODE.User_Password_Expired)
      } else if (error_code === ERROR_API_CODE.EndUser_Password_Expired) {
        onNext(true, ERROR_API_CODE.EndUser_Password_Expired)
      } else {
        if (error_message && Array.isArray(error_message) && error_message.length > 0) {
          if (error_message[0] === "End user is locked") {
            setError(data)
          } else {
            setError(error_message[0])
          }
        } else {
          setError("Something went wrong!")
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await handleSetDataTranslateErrorMsg()
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (!tabId) {
      sessionStorage.tabID = Math.random()
    }
    if (error) setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_377")}
            </label>
            <input
              onChange={(e) => onChange(e.target.value)}
              onFocus={(e) => setError("")}
              value={username}
              type="email"
              name="email"
              id="email"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              placeholder={t("79_373")}
              required
            />
            <span className="relative float-left left-2 -top-[34px]">{iconEmail}</span>
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_385")}
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              onFocus={(e) => setError("")}
              value={password}
              type={showPass ? "text" : "password"}
              name="password"
              id="password"
              placeholder={t("79_380")}
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              required
            />
            <span className="relative float-left left-1 -top-[35px]">{iconPassword}</span>
            {password.length > 0 && (
              <span className="field-icon cursor-pointer" onClick={() => setShowPass(!showPass)}>
                {showPass ? iconShow : iconHide}
              </span>
            )}
          </div>
          {error !== "" && <div className="margin-text-top text-red-500">{error}</div>}
          <CustomButton title="Sign In" loading={loading} disabled={error !== "" ? true : false} />
          <div
            className="text-[14px] hover:underline hover:cursor-pointer text-[#000000E0] text-center"
            onClick={onForgot}
          >
            {t("79_393")}
          </div>
          <div>
            <p className="text-center text-[14px] text-[#000000A6] font-[400]">
              {t("79_399")} {t("79_400")}
            </p>
            <p className="text-center">
              <a
                href="https://organic-mobility.com/privacy-policy"
                className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]"
              >
                {t("111_17")}
              </a>
              <span className="m-5">/</span>
              <a
                href="https://organic-mobility.com/terms"
                className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]"
              >
                {t("111_18")}
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
